import React, { Component } from 'react'
import {NumericInput, Checkbox, Dialog, Button, Intent } from '@blueprintjs/core'

class EditUser extends Component {
  constructor(props) {
    super(props)

    this.state = props.data ? {
      passcode: props.data.passcode,
      quota: props.data.quota
    } : null
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({passcode: nextProps.data.passcode, quota: nextProps.data.quota})
    }
  }

  handleEdit(key, value) {
    if (key === 'useCustomQuota') {
      this.setState({quota: value.target.checked ? this.props.company.defaultQuotaPerUser : null})
    } else if (key === 'quota') {
      this.setState({quota: value})
    } else if (key === 'passcode') {
      this.setState({passcode: value.target.value})
    }
  }

  randomPasscode = () => {
    let passcode = ''
    var possible = '0123456789'

    for(let i = 0; i < 8; i++) {
      passcode += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    this.setState({passcode})
  }

  render() {
    if (!this.props.data) {
      return null
    }

    return (
      <Dialog
        iconName="edit"
        isOpen={true}
        onClose={this.props.onClose}
        title={`Edit ${this.props.data.user}`}
      >
        <div className="pt-dialog-body">
          <div className="pt-form-group">
            <label className="pt-label" htmlFor="passcode">
              Passcode
            </label>
            <div className="pt-form-content">
              <div className="pt-input-group">
                <input id="passcode" className="pt-input block-element" type="text" value={this.state.passcode} onChange={this.handleEdit.bind(this, 'passcode')} />
                <Button className="pt-minimal pt-icon-refresh" onClick={this.randomPasscode}/>
              </div>
            </div>
          </div>
          <div className="pt-card">
            <Checkbox checked={this.state.quota !== null} label="Set independent device quota for this user" onChange={this.handleEdit.bind(this, 'useCustomQuota')} />
            {
              this.state.quota === null ||
                <NumericInput
                  onValueChange={this.handleEdit.bind(this, 'quota')}
                  value={this.state.quota}
                />
            }
          </div>
        </div>
        <div className="pt-dialog-footer">
          <div className="pt-dialog-footer-actions">
            <Button onClick={this.props.onClose} text="Cancel" />
            <Button
              intent={Intent.PRIMARY}
              onClick={() => this.props.onSave(this.state, this.props.data)}
              text="Save"
            />
          </div>
        </div>
      </Dialog>
    )
  }
}

export default EditUser
