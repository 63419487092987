import React, {Component} from 'react'
import {Button} from '@blueprintjs/core'
import {post, login} from './util'
import logo from './logo.png'
 
class Login extends Component {
  state = {
    email: '',
    password: '',
    loading: false
  }

  onChange(key, value) {
    this.setState({[key]: value.target.value})
  }

  onSubmit = e => {
    e.preventDefault()

    this.setState({loading: true})

    post('admin/login', {email: this.state.email, password: this.state.password}).then(ret => {
      this.setState({loading: false})
      login(ret.token)
      this.props.history.push('/dashboard')
    }).catch(e => {
      this.setState({loading: false})
      alert(e.message)
    })
  }

  render() {
    return (
      <div style={{width: 300, margin: '200px auto'}}>
        <form onSubmit={this.onSubmit}>
          <div style={{position: 'relative', textAlign: 'left', marginBottom: 16, fontSize: 20, paddingTop: 14}}>
            <img style={{position: 'absolute', right: 0, top: 0}} src={logo} alt="Logo" width="40" height="40"/>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push('/')}>Surge Enterprise</span>
          </div>
          <div className="pt-form-group">
            <div className="pt-form-content">
              <input id="email" className="pt-input" style={{width: 300}} placeholder="Your Email" type="text" value={this.state.email} onChange={this.onChange.bind(this, 'email')} />
            </div>
          </div>
          <div className="pt-form-group">
            <div className="pt-form-content">
              <input id="password" className="pt-input" style={{width: 300}} type="password" placeholder="Password" value={this.state.password} onChange={this.onChange.bind(this, 'password')} />
            </div>
          </div>
          <Button iconName="log-in" type="submit" loading={this.state.loading} text="Sign In" />
        </form>
      </div>
    )
  }
}

export default Login
