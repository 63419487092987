import React, { PureComponent } from 'react'
import { get, post, put, logout } from './util'
import { Alert, Intent, Button, Spinner, Dialog, Tab2, Tabs2, NumericInput } from '@blueprintjs/core'
import moment from 'moment'

const EMPTY_TOKEN = '$EMPTY_$TOKEN'

class Profile extends PureComponent {
  state = {
    currentAdminPassword: '',
    adminPassword: '',
    passwordAgain: '',
    confirmReset: false
  }

  handleClose = () => {
    this.props.onClose()
  }

  handleEdit = (key, { target: { value } }) => {
    this.setState({ [key]: value })
  }

  handleUpdate = () => {
    if (!this.state.currentAdminPassword) {
      alert('Please enter the current password')
      return
    }
    if (!this.state.adminPassword) {
      alert('Please enter the new password')
      return
    }
    if (this.state.passwordAgain !== this.state.adminPassword) {
      alert('Two passwords are not same')
      return
    }
    put('admin/info', {
      adminPassword: this.state.adminPassword, currentAdminPassword: this.state.currentAdminPassword
    }).then(() => {
      alert('Updated successfully. Please sign in again.')
      logout()
      this.props.history.push('/login')
    }).catch(e => {
      alert('Failed to update: ' + e.message)
    })
  }

  saveQuota = () => {
    put('admin/info', {
      defaultQuotaPerUser: this.state.defaultQuota
    }).then(() => {
      alert('Saved successfully')
      this.props.onChangeDefaultQuota(this.state.defaultQuota)
    }).catch(e => {
      alert('Failed to Save: ' + e.message)
    })
  }

  resetToken = () => {
    const loadingToken = '........-....-....-....-............'
    this.setState({ confirmReset: false, token: loadingToken })
    post('admin/api-token', {}).then(({ apiToken }) => {
      this.setState({ token: loadingToken })
      setTimeout(() => {
        this.setState({ token: apiToken })
      }, 1000)
    }).catch(e => {
      alert('Failed to reset: ' + e.message)
    })
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.show && nextProps.show) {
      this.setState({ loading: true })
      get('admin/info').then(company => {
        this.setState({ company, loading: false, defaultQuota: company.defaultQuotaPerUser })
      }).catch(e => {
        console.error(e)
      })

      get('admin/api-token').then(({ apiToken }) => this.setState({ token: apiToken || EMPTY_TOKEN }))
    }
  }

  renderTable() {
    if (!this.state.company) {
      return null
    }

    return (<table className="pt-table pt-striped no-border-top" style={{ width: '100%' }}>
      <tbody>
        <tr>
          <td width={140}><strong>Company ID</strong></td>
          <td>{this.state.company.companyID}</td>
        </tr>
        <tr>
          <td width={140}><strong>Company Name</strong></td>
          <td>{this.state.company.companyName}</td>
        </tr>
        <tr>
          <td><strong>Admin Email</strong></td>
          <td>{this.state.company.adminEmail}</td>
        </tr>
        <tr>
          <td><strong>Device Quota</strong></td>
          <td>{this.state.company.quota}</td>
        </tr>
        <tr>
          <td><strong>Used Quota</strong></td>
          <td style={{ position: 'relative' }}>{this.state.company.usedQuota} <Spinner value={this.state.company.usedQuota / this.state.company.quota} className="pt-small quota-used" /></td>
        </tr>
        <tr>
          <td><strong>Expired Date</strong></td>
          <td>{moment(this.state.company.expiredDate).format('dddd, MMMM Do YYYY')}</td>
        </tr>
      </tbody>
    </table>)
  }

  renderPassword() {
    return (
      <div>
        <div className="pt-control-group pt-vertical">
          <div className="pt-input-group">
            <input value={this.state.currentAdminPassword} type="text" className="pt-input" onChange={this.handleEdit.bind(this, 'currentAdminPassword')} placeholder="Current Password" />
            <span className="pt-icon pt-icon-lock"></span>
          </div>
          <div className="pt-input-group">
            <input value={this.state.adminPassword} type="text" className="pt-input" onChange={this.handleEdit.bind(this, 'adminPassword')} placeholder="New Password" />
          </div>
          <div className="pt-input-group">
            <input value={this.state.passwordAgain} type="text" className="pt-input" onChange={this.handleEdit.bind(this, 'passwordAgain')} placeholder="New Password Again" />
          </div>
        </div>
        <Button style={{ marginTop: 8 }} className="pt-intent-primary" onClick={this.handleUpdate}>Update Password</Button>
      </div>
    )
  }

  renderQuota() {
    if (!this.state.company) {
      return null
    }

    return (
      <div>
        <NumericInput
          min={0}
          max={this.state.company.quota}
          value={this.state.defaultQuota}
          onValueChange={defaultQuota => this.setState({ defaultQuota })} />
        <Button style={{ marginTop: 8 }} className="pt-intent-primary" onClick={this.saveQuota}>Save</Button>
      </div>
    )
  }

  renderToken() {
    if (!this.state.token) {
      return null
    }

    return (
      <div>
        {
          this.state.token === EMPTY_TOKEN
            ? <p>No Token</p>
            : <p>Current Token: <code>{this.state.token}</code></p>
        }
        <Button style={{ marginTop: 8 }} className="pt-intent-primary" onClick={() => this.setState({ confirmReset: true })}>Reset</Button>
        <div
          style={{ marginTop: 20, fontSize: 12, color: '#5c7080' }}
        >
          Contact support@nssurge.com if you want to use API to update user list.
        </div>

      </div>
    )
  }

  render() {
    if (this.props.show && this.state.loading) {
      return <Spinner className="fullscreen-loading" />
    }

    return (
      <Dialog
        isOpen={this.props.show}
        onClose={this.handleClose}
        iconName="user"
        title="Enterprise Account"
        style={{ width: 500 }}
      >
        <Alert
          isOpen={this.state.confirmReset}
          intent={Intent.DANGER}
          iconName='confirm'
          confirmButtonText='Reset'
          cancelButtonText='Cancel'
          onCancel={() => this.setState({ confirmReset: false })}
          onConfirm={this.resetToken}
        >
          <span>Are you sure you want to reset the API token? This action cannot be undone.</span>
        </Alert>
        <div className="pt-dialog-body">
          <Tabs2 onChange={this.handleTabChange}>
            <Tab2 id="details" title="Details" panel={this.renderTable()} />
            <Tab2 id="password" title="Update Password" panel={this.renderPassword()} />
            <Tab2 id="quota" title="Default User Quota" panel={this.renderQuota()} />
            <Tab2 id="token" title="API Token" panel={this.renderToken()} />
          </Tabs2>
        </div>
      </Dialog>
    )
  }
}

export default Profile
