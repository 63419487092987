import React, { PureComponent } from 'react'
import {get, getToken, logout} from './util'
import {Spinner, Button, Tooltip, Position} from '@blueprintjs/core'
import './App.css'
import Landing from './Landing'
import User from './User'
import Device from './Device'
import Config from './Config'
import Profile from './Profile'
import {Route} from 'react-router-dom'

class App extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      nav: props.location.pathname.split('/').pop(),
      loading: true
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState({nav: nextProps.location.pathname.split('/').pop()})
    }
  }

  componentWillMount () {
    if (!getToken()) {
      this.props.history.push('/login')
      this.redirected = true
    }
  }

  componentDidMount () {
    if (this.redirected) {
      return
    }
    get('admin/info').then(company => {
      this.setState({company, loading: false})
    }).catch(e => {
      console.error(e)
    })
  }

  onSelect (nav) {
    this.setState({nav})
    this.props.history.push(`/dashboard/${nav}`)
  }

  render () {
    if (this.state.loading) {
      return <Spinner className='fullscreen-loading' style={{margin: '200px auto'}} />
    }

    const {match} = this.props

    let allowsChangingProfile = this.state.company.allowsChangingProfile;

    return (
      <div style={{width: 960}}>
        <nav className='pt-navbar'>
          <div className='pt-navbar-group pt-align-left'>
            <div
              className='pt-navbar-heading'
              style={{cursor: 'pointer'}}
              onClick={() => this.props.history.push('/dashboard')}
            >{this.state.company.companyName}</div>
          </div>
          <div className='pt-navbar-group pt-align-right'>
            <Button className='pt-minimal' onClick={this.onSelect.bind(this, 'users')} active={this.state.nav === 'users'}>Users</Button>
            <Button className='pt-minimal' onClick={this.onSelect.bind(this, 'devices')} active={this.state.nav === 'devices'}>Devices</Button>
            {allowsChangingProfile && <Button className='pt-minimal' onClick={this.onSelect.bind(this, 'unified-profile')} active={this.state.nav === 'unified-profile'}>Unified Profile</Button> }
            <span className='pt-navbar-divider' />
            <Tooltip position={Position.BOTTOM} content='Account'>
              <Button className='pt-minimal pt-icon-user' onClick={() => this.setState({showProfile: true})} />
            </Tooltip>
            <Tooltip position={Position.BOTTOM} content='Logout'>
              <Button className='pt-minimal pt-icon-log-out' onClick={() => {
                logout()
                this.props.history.push(`/login`)
              }} />
            </Tooltip>
          </div>
        </nav>

        <div style={{paddingTop: 20}}>
          <Route exact path={`${match.url}`} render={props => <Landing company={this.state.company} history={this.props.history} />} />
          <Route path={`${match.url}/users`} render={props => <User company={this.state.company} />} />
          <Route path={`${match.url}/devices`} render={props => <Device />} />
          <Route path={`${match.url}/unified-profile`} render={props => <Config />} />
        </div>
        <Profile
          history={this.props.history}
          show={this.state.showProfile}
          onClose={() => this.setState({showProfile: false})}
          onChangeDefaultQuota={defaultQuotaPerUser => {
            this.setState({company: Object.assign({}, this.state.company, {defaultQuotaPerUser})})
            this.setState({showProfile: false})
          }}
        />
      </div>
    )
  }
}

export default App
