import React, { Component } from 'react'
import {getToken} from './util'
import logo from './Home/logo.png'
import twitterIcon from './Home/icon-twitter.png'
import {Link} from 'react-router-dom'
import './Home/index.css'

class Home extends Component {
  goToDashboard = e => {
    e.preventDefault()
    if (getToken()) {
      this.props.history.push('/dashboard')
    } else {
      this.props.history.push('/login')
    }
  }

  render() {
    return (
      <div className="home">
        <div className="slogan">
          <div className="logo"><img src={logo} width={191} height={20} alt="Surge Enterprise"/></div>
          <nav>
            <Link to="/">Home</Link>
            <a href="https://manual.nssurge.com/">Manual</a>
            <a href="#dashboard" onClick={this.goToDashboard}>Dashboard</a>
          </nav>
          <div className="iphone"/>
          <div className="introduce">
            <p>Surge Enterprise is now available. You can purchase Surge for all your employees to enjoy more powerful enterprise features.</p>
            <div className="button-group">
              <button className="purchase-btn"><a href="#purchase" onClick={() => alert('Surge Enterprise is currently in beta. Please contact us with a company email if you want a beta access.')}>Purchase</a></button>
              <button className="contact-btn"><a href="mailto:enterprise@nssurge.com">Contact</a></button>
            </div>
          </div>
        </div>
        <div className="description">
          <p>For developers, Surge is a powerful network toolbox for daily works. And for others, Surge can be used as an automatic security gateway to access sensitive enterprise network. All they need to do is to download, login and click start button.</p>
        </div>
        <div className="feature-list">
          <div className="features feature-unified-profile">
            <h3>Unified Profile</h3>
            <p className="pt-ui-text">The users will receive the unified Surge profile after registering their devices. And the profile can be configured to update automatically. The admin can edit the profile via the admin panel.</p>
          </div>
          <div className="features feature-security">
            <h3>Security</h3>
            <p className="pt-ui-text">The unified profile can't be edited or exported by the user. After the admin unregisters a device, the license will be revoked and the profile will be deleted in 24 hours.</p>
          </div>
        </div>
        <div className="feature-list" style={{paddingBottom: 60}}>
          <div className="features feature-user-system">
            <h3>User System</h3>
            <p className="pt-ui-text">You can add sub-users to your company account, and assign device license quota for users individually. You can also push different Surge profiles to different users. (A simple HTTP service is required)</p>
          </div>
          <div className="features feature-license">
            <h3>License Management</h3>
            <p className="pt-ui-text">You may purchase the license by device. The device can be easily registered under your company, and can be unregistered anytime to release the slot.</p>
          </div>
        </div>
        <div className="platform">
          <div className="content-box">
            <h3>Multiple Platform</h3>
            <p>Surge Mac and Surge iOS are both supported. You may deploy unified profile for all devices.</p>
          </div>
        </div>
        <div className="contact">
          <h3>Feel free to contact us if you have any question.</h3>
          <button><a href="mailto:enterprise@nssurge.com">Contact Us</a></button>
        </div>
        <div className="footer">
          © 2021 Surge Networks Inc. | <Link to="/privacy">Terms &amp; Privacy</Link>
          <a className="twitter" href="https://twitter.com/SurgeDebugger"><img src={twitterIcon} alt="twitter" width={24} height={20}/></a>
        </div>
      </div>
    )
  }
}

export default Home
