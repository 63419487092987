import React, { Component } from 'react'
import {get, put, post, destroy} from './util'
import EditUser from './EditUser'
import AddUser from './AddUser'
import ImportCSV from './ImportCSV'
import {Spinner, Button, Menu, MenuItem, Intent, Toaster, Alert, NonIdealState} from '@blueprintjs/core'

class User extends Component {
  refHandlers = {
    toaster: ref => this.toaster = ref
  }

  state = {
    loading: true,
    users: [],
    edit: null,
    editLoading: false
  }

  columns = [
    {label: 'User', prop: 'user'},
    {label: 'Used Quota', prop: 'deviceCount'},
    {label: 'Device Quota', render: data => (
      data.quota || this.props.company.defaultQuotaPerUser
    )},
    {label: 'Passcode', prop: 'passcode'},
    {label: '', render: data => (
      <span>
        <Button plain={true} type="info" size="small" onClick={this.onEdit.bind(this, data)}>Edit</Button>
        <Button type="danger" size="small" onClick={this.onDelete.bind(this, data)}>Delete</Button>
      </span>
    )},
  ]

  componentDidMount() {
    get('admin/users').then(users => {
      this.setState({users, loading: false})
    })
  }

  addUser = user => {
    if (!user) {
      this.toaster.show({message: 'Username should not be empty', intent: Intent.DANGER});
      return
    }
    post('admin/users', {user}).then(user => {
      user.deviceCount = 0
      this.setState({adding: false, users: this.state.users.concat(user)})
      this.toaster.show({message: 'Created Successfully', intent: Intent.SUCCESS})
    }).catch(e => {
      this.toaster.show({message: `Created Failed: ${e.message}`, intent: Intent.DANGER});
    })
  }

  renderTable(users) {
    if (!users.length) {
      return (<NonIdealState
        title="No users"
        visual="user"
      />)
    }

    return (
      <table className="pt-table pt-interactive" style={{width: '100%'}}>
        <thead>
          <tr>
            <th>User</th>
            <th width={130}>Used Quota</th>
            <th width={130}>Device Quota</th>
            <th width={150}>Passcode</th>
            <th width={80}></th>
          </tr>
        </thead>
        <tbody>
          {
            users.map(user => (
              <tr key={user.id} onMouseOver={() => {
                this.setState({activeId: user.id})
              }}>
                <td>{user.user}</td>
                <td>{user.deviceCount}</td>
                <td className={user.quota === null ? 'default-quota' : ''}>{user.quota || this.props.company.defaultQuotaPerUser}</td>
                <td><code>{user.id === this.state.activeId ? user.passcode : user.passcode.replace(/./g, '*')}</code></td>
                <td>{
                  user.id === this.state.activeId &&
                  <div style={{height: 16}}>
                    <Button iconName="edit" className="pt-minimal btn-small" onClick={() => this.setState({edit: user})} />
                    <Button iconName="trash" className="pt-minimal btn-small" intent={Intent.DANGER} onClick={() => this.setState({remove: user})} />
                  </div>
                }
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    )
  }

  renderMenu() {
    return (
      <Menu className="pt-elevation-1 no-round">
        <MenuItem
          iconName="new-person"
          text="Create User"
          onClick={() => this.setState({adding: true})}
        />
        <MenuItem
          iconName="upload"
          onClick={() => this.setState({csv: true})}
          text="Import CSV"
        />
      </Menu>
    )
  }

  render() {
    if (this.state.loading) {
      return <Spinner className="fullscreen-loading"/>
    }
    return (
      <div>
        <Alert
          isOpen={this.state.remove}
          intent={Intent.DANGER}
          iconName='trash'
          confirmButtonText='Remove'
          cancelButtonText='Cancel'
          onCancel={() => {
            this.setState({remove: null})
          }}
          onConfirm={() => {
            const {id} = this.state.remove
            destroy(`admin/users/${id}`).then(() => {
              this.setState({remove: null, users: this.state.users.filter(user => user.id !== id)})
              this.toaster.show({message: 'Removed Successfully', intent: Intent.SUCCESS})
            }).catch(e => {
              this.setState({remove: null})
              this.toaster.show({message: `Removed Failed: ${e.message}`, intent: Intent.DANGER})
            })
          }}
        >
          <span>Are you sure you want to delete "{this.state.remove ? this.state.remove.user : 'this user'}"? The user's devices will be unregistered also. This action cannot be undone.</span>
        </Alert>
        <Toaster ref={this.refHandlers.toaster} />
        <div className="grid-container">
          <div className="grid-left">
            {this.renderMenu()}
          </div>
          <div className="grid-right">
            {this.renderTable(this.state.users)}
          </div>
        </div>
        <ImportCSV
          show={this.state.csv}
          defaultQuota={this.props.company.defaultQuotaPerUser}
          onClose={() => this.setState({csv: null})}
          onSave={() => {
            this.setState({loading: true, csv: null})
            get('admin/users').then(users => {
              this.setState({users, loading: false})
            })
          }}
        />
        <AddUser
          show={this.state.adding}
          onClose={() => this.setState({adding: null})}
          onSave={this.addUser}
        />
        <EditUser
          company={this.props.company}
          data={this.state.edit}
          onClose={() => this.setState({edit: null})}
          onSave={(data, {id}) => {
            put(`admin/users/${id}`, data).then(() => {
              this.setState({
                editLoading: false,
                edit: null,
                users: this.state.users.map(user => user.id === id ? Object.assign(user, data) : user)
              })
              this.toaster.show({message: 'Updated Successfully', intent: Intent.SUCCESS})
            }).catch(e => {
              this.setState({editLoading: false, edit: null})
              this.toaster.show({message: 'Updated Failed: ' + e.message, intent: Intent.DANGER});
            })
          }}
        />
      </div>
    )
  }
}

export default User
