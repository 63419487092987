/* global StripeCheckout */
import React, { Component } from 'react'
import { get, post } from './util'
import { Spinner, Button, Intent, Dialog, NumericInput, RadioGroup, Radio } from '@blueprintjs/core'
import moment from 'moment'
import InstallGuide from './InstallGuide'

function GoTo(props) {
  return <a href={`#${props.tab}`} onClick={e => {
    e.preventDefault()
    props.history.push(`/dashboard/${props.tab}`)
  }}>{props.children}</a>
}

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
          return decodeURIComponent(pair[1]);
      }
  }
}

class Landing extends Component {
  refHandlers = {
    toaster: ref => this.toaster = ref
  }

  state = {
    show: false,
    loading: false,
    paymentMethod: 'stripe'
  }

  componentDidMount() {
    this.setState({invoiceEmail: this.props.company.adminEmail})
    this.setState({renewQuota: this.props.company.quota})
    this.setState({minQuota: Math.max(this.props.company.usedQuota, 20) })
    
    const currentQuota = this.props.company.quota;
    
    get(`admin/payment?type=renew&quota=${currentQuota}`).then(ret => {
      if (this.state.renewQuota !== currentQuota) {
        return
      }
      this.setState({
        remainingDays: ret.remainingDays,
        renewAmountCNY: ret.amountCNY,
        renewAmountUSD: ret.amountUSD
      })
    })

    const delay = getQueryVariable('delay')
    if (delay) {
      setTimeout(() => {
        this.setState({show: true})
      }, Number(delay) * 1000)
    } else {
      this.setState({show: true})
    }
  }

  openStripe({description, amount, tokenCallback, closedCallback}) {
    const handler = StripeCheckout.configure({
      key: 'pk_live_qE5hyWrgfn75XQdEdvPKqGLq',
      image: 'https://nssurge.com/img/stripe-icon.png',
      locale: 'auto',
      email: this.props.company.adminEmail,
      token: tokenCallback,
      closed: closedCallback
    });
    handler.open({
      name: 'Surge',
      description: 'Surge Enterprise',
      amount: amount * 100
    });
  }

  goTo(tab) {
    this.props.history.push('/dashboard/unified-profile')
  }

  renderPrice(add = true) {
    const key = add ? 'addQuotaAmount' : 'renewAmount' 
    if (this.state.paymentMethod === 'alipay' && this.state[key + 'CNY']) {
      return <span style={{float: 'left'}}>Total: RMB￥{this.state[key + 'CNY']}</span>
    }
    if (this.state.paymentMethod === 'stripe' && this.state[key + 'USD']) {
      return <span style={{float: 'left'}}>Total: USD${this.state[key + 'USD']}</span>
    }
    return null
  }

  renderEnhance() {
    return <Dialog
      isOpen={this.state.enhanceShow}
      onClose={() => this.setState({enhanceShow: false})}
      iconName="credit-card"
      title="Add Quota"
      style={{ width: 600 }}
    >
      <div className="pt-dialog-body">
        <table className="pt-table pt-striped no-border-top" style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td width={140}><strong>Current Quota</strong></td>
              <td>{this.props.company.quota}</td>
            </tr>
            <tr>
              <td width={140} style={{verticalAlign: 'middle'}}><strong>Buy Extra Quota</strong></td>
              <td>
                <NumericInput min={1} value={this.state.newQuota || 0} onValueChange={
                  v => {
                    const total = v + this.props.company.quota
                    this.setState({newQuota: v, addQuotaAmountCNY: null, addQuotaAmountUSD: null}, () => {
                      if (this.state.newQuota !== v) {
                        return
                      }
                      get(`admin/payment?type=add-quota&quota=${total}`).then(ret => {
                        if (this.state.newQuota !== v) {
                          return
                        }
                        this.setState({
                          remainingDays: ret.remainingDays,
                          addQuotaAmountCNY: ret.amountCNY,
                          addQuotaAmountUSD: ret.amountUSD
                        })
                      })
                    })
                  }
                } />
              </td>
            </tr>
            <tr>
              <td width={140}><strong>New Quota</strong></td>
              <td>{this.props.company.quota + (this.state.newQuota || 0)}</td>
            </tr>
            <tr>
              <td width={140}><strong>Remaining Days</strong></td>
              <td>{this.state.remainingDays && Math.round(this.state.remainingDays)}</td>
            </tr>
            <tr>
              <td width={140}><strong>Payment</strong></td>
              <td style={{ position: 'relative', verticalAlign: 'middle' }}>
                <RadioGroup
                  onChange={e => this.setState({paymentMethod: e.target.value})}
                  selectedValue={this.state.paymentMethod}
                >
                  <Radio className="pt-inline" label="Credit Card (USD)" value="stripe" />
                </RadioGroup>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="pt-dialog-footer">
        {this.renderPrice()}
        <div className="pt-dialog-footer-actions">
          <Button onClick={() => this.setState({enhanceShow: false})} text="Cancel" />
          <Button
            loading={this.state.quotaPaying || false}
            disabled={!this.state.addQuotaAmountCNY}
            intent={Intent.PRIMARY}
            onClick={() => {
              this.setState({quotaPaying: true})
              if (this.state.paymentMethod === 'stripe') {
                this.openStripe({
                  amount: this.state.addQuotaAmountUSD,
                  description: 'Surge Enterprise',
                  tokenCallback: token => {
                    post('admin/payment', {
                      type: 'add-quota',
                      stripeToken: token.id,
                      channel: 'stripe',
                      quota: this.state.newQuota + this.props.company.quota
                    }).then(ret => {
                      if (ret.error) {
                        alert(`Payment failed: ${ret.error}`)
                      }
                      window.location.reload()
                    }).catch(e => {
                      alert(`Payment failed: ${e.message}`)
                      window.location.reload()
                    })
                  },
                  closedCallback: () => {
                    this.setState({quotaPaying: false})
                  }
                })
              } else {
                // post('admin/payment', {
                //   type: 'add-quota', channel: 'alipay', quota: this.state.newQuota + this.props.company.quota
                // }).then(ret => {
                //   window.location.href = ret.url
                // }).catch(e => {
                //   alert(`Create payment failed: ${e.message}`)
                //   this.setState({quotaPaying: false})
                // })
              }
            }}
            type="submit"
            text="Continue"
          />
        </div>
      </div>
    </Dialog>
  }

  renderRenew() {
    return <Dialog
      isOpen={this.state.renewShow}
      onClose={() => this.setState({ renewShow: false })}
      iconName="credit-card"
      title="Renew"
      style={{ width: 600 }}
    >
      <div className="pt-dialog-body">
        <table className="pt-table pt-striped no-border-top" style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td width={140} style={{verticalAlign: 'middle'}}><strong>Device Quota</strong></td>
              <td>
                <NumericInput min={this.state.minQuota} value={this.state.renewQuota} onValueChange={
                  v => {
                    let total = v
                    
                    if (total < this.state.minQuota) {
                      alert(`The quota must greater than ${this.state.minQuota}.`)
                      this.setState({renewQuota: this.state.minQuota})
                      total = this.state.minQuota;
                    }
                    
                    this.setState({renewQuota: total, renewAmountCNY: null, renewAmountUSD: null}, () => {
                      if (this.state.renewQuota !== total) {
                        return
                      }
                      get(`admin/payment?type=renew&quota=${total}`).then(ret => {
                        if (this.state.renewQuota !== total) {
                          return
                        }
                        this.setState({
                          remainingDays: ret.remainingDays,
                          renewAmountCNY: ret.amountCNY,
                          renewAmountUSD: ret.amountUSD
                        })
                      })
                    })
                  }
                } />
              </td>
            </tr>
            
            <tr>
              <td width={140}><strong>Expiration Date</strong></td>
              <td>{moment(this.props.company.expiredDate).format('dddd, MMMM Do YYYY')}</td>
            </tr>
            <tr>
              <td style={{verticalAlign: 'middle'}} width={140}><strong>Renew</strong></td>
              <td>
                <div className="pt-select pt-fill">
                  <select defaultValue="1">
                    <option value="1">1 Year</option>
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td width={140}><strong>Extend to</strong></td>
              <td>{moment(this.state.date).format('dddd, MMMM Do YYYY')}</td>
            </tr>
            <tr>
              <td width={140}><strong>Payment</strong></td>
              <td>
                <RadioGroup
                  onChange={e => this.setState({paymentMethod: e.target.value})}
                  selectedValue={this.state.paymentMethod}
                >
                  <Radio className="pt-inline" label="Credit Card (USD)" value="stripe" />
                </RadioGroup>
              </td>
            </tr>
          </tbody>
        </table>
        {/* <div className="pt-card"> */}
          {/* <label className="pt-control pt-checkbox">
            <input type="checkbox" checked={this.state.invoice || false} onChange={e => this.setState({ invoice: e.target.checked })} />
            <span className="pt-control-indicator"></span>
            Need Invoice (China)
          </label>
          {this.state.invoice &&
            <div>
              <table className="pt-table pt-striped" style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{verticalAlign: 'middle'}} width={140}><strong>公司名称</strong></td>
                    <td>
                      <input
                        className="pt-input pt-fill"
                        type="text"
                        value={this.state.invoiceTitle || ''}
                        onChange={e => this.setState({ invoiceTitle: e.target.value })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{verticalAlign: 'middle'}} width={140}><strong>税号</strong></td>
                    <td>
                      <input
                        className="pt-input pt-fill"
                        type="text"
                        value={this.state.invoiceNo || ''}
                        onChange={e => this.setState({ invoiceNo: e.target.value })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{verticalAlign: 'middle'}} width={140}><strong>电子发票收件邮箱</strong></td>
                    <td>
                      <input
                        className="pt-input pt-fill"
                        type="text"
                        value={this.state.invoiceEmail || ''}
                        onChange={e => this.setState({ invoiceEmail: e.target.value })}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                style={{ marginTop: 5, fontSize: 12, color: '#5c7080' }}
              >
                仅当使用支付宝支付时提供，可开具增值税电子普通发票，类目为其他软件服务。
                    </div>
            </div>} */}
        {/* </div> */}
      </div>
      <div className="pt-dialog-footer">
        {this.renderPrice(false)}
        <div className="pt-dialog-footer-actions">
          <Button onClick={() => this.setState({ renewShow: false })} text="Cancel" />
          <Button
            loading={this.state.paying || false}
            intent={Intent.PRIMARY}
            onClick={() => {
              this.setState({ paying: true })
              const invoice = this.state.invoice ? {
                title: this.state.invoiceTitle,
                no: this.state.invoiceNo,
                email: this.state.invoiceEmail
              } : null


              if (this.state.paymentMethod === 'stripe') {
                this.openStripe({
                  amount: this.state.renewAmountUSD,
                  description: 'Surge Enterprise',
                  tokenCallback: token => {
                    post('admin/payment', {
                      type: 'renew', invoice, channel: 'stripe', stripeToken: token.id, quota: this.state.renewQuota
                    }).then(ret => {
                      if (ret.error) {
                        alert(`Payment failed: ${ret.error}`)
                      }
                      window.location.reload()
                    }).catch(e => {
                      alert(`Payment failed: ${e.message}`)
                      window.location.reload()
                    })
                  },
                  closedCallback: () => {
                    this.setState({paying: false})
                  }
                })
              } else {
                // post('admin/payment', { type: 'renew', invoice, channel: 'alipay', quota: this.state.renewQuota }).then(ret => {
                //   window.location.href = ret.url
                // }).catch(e => {
                //   alert(`Create payment failed: ${e.message}`)
                //   this.setState({ paying: false})
                // })
              }
            }}
            type="submit"
            text="Continue"
          />
        </div>
      </div>
    </Dialog>
  }

  render() {
    if (this.state.loading) {
      return <Spinner className="fullscreen-loading" />
    }
    if (!this.state.show) {
      return <Spinner className="fullscreen-loading" />
    }

    const threshold = 35 * 24 * 3600 * 1000
    const remain = new Date(this.props.company.expiredDate) - new Date()
    return (
      <div className="pt-ui-text-large landing">
        {this.renderRenew()}
        {this.renderEnhance()}
        <h1>Welcome</h1>
        {
          remain < threshold &&
          <div className="pt-callout pt-intent-warning">
            <h5>Notice</h5>
            {
              remain > 0
                ? `Your subscription will end in ${Math.floor(remain / 3600000 / 24)} days.`
                : 'Your subscription has expired.'
            }
            <Button style={{marginLeft: 5}} onClick={() => this.setState({renewShow: true})}>Renew Now</Button>
          </div>
        }
        <h2>Getting Started</h2>
        <ol>
          <li>Create new users for the end user in the <GoTo history={this.props.history} tab="users">Users</GoTo> tab.</li>
          <li>You can deploy the unified profile for all users. Configure it in <GoTo history={this.props.history} tab="unified-profile">Unified Profile</GoTo> tab. (Optional)</li>
          <li>
            Guide your end users to download Surge app.
            <div style={{ marginTop: 8, paddingLeft: 20 }}> 
            <InstallGuide />
            </div>
          </li>
          <li>You may share the guide to your end users with this link: <a href="https://enterprise.nssurge.com/install" target="_blank" rel="noopener noreferrer">https://enterprise.nssurge.com/install</a></li>
        </ol>
        <p>For any further questions, please contact <a href="mailto:enterprise@nssurge.com">enterprise@nssurge.com</a>.</p>
        <h2>License Status {remain > 3600 * 1000 && <Button onClick={() => this.setState({enhanceShow: true})}>Add Quota</Button>}</h2>
        <p><strong className="label">Expiration Date</strong> {moment(this.props.company.expiredDate).format('dddd, MMMM Do YYYY')}</p>
        <p><strong className="label">Device Quota</strong> {this.props.company.usedQuota} / {this.props.company.quota} (Used / Total)</p>
      </div>
    )
  }
}

export default Landing
