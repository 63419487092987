import React, { Component } from 'react';
import { get, destroy } from './util';
import { Button, Intent, Spinner, Alert, Toaster, NonIdealState } from '@blueprintjs/core';
import moment from 'moment';

class Device extends Component {
  refHandlers = {
    toaster: (ref) => (this.toaster = ref),
  };

  state = {
    loading: true,
    devices: [],
    sortColumn: 'user',
    sortDirection: 'asc',
  };

  componentDidMount() {
    get('admin/devices').then((devices) => {
      this.setState({ devices, loading: false });
    });
  }

  onSortChange = (column) => {
    if (this.state.sortColumn === column) {
      this.setState({ sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' });
    } else {
      this.setState({ sortColumn: column, sortDirection: 'asc' });
    }
  };

  sortDevices = (devices) => {
    const { sortColumn, sortDirection } = this.state;
    const sortedDevices = [...devices].sort((a, b) => {
      let comparison = 0;

      if (sortColumn === 'registerDate' || sortColumn === 'lastOnlineDate') {
        comparison = moment(a[sortColumn]).diff(moment(b[sortColumn]));
      } else {
        comparison = a[sortColumn].toString().localeCompare(b[sortColumn].toString());
      }

      return sortDirection === 'asc' ? comparison : -comparison;
    });

    return sortedDevices;
  };

  renderTable(devices) {
    const sortedDevices = this.sortDevices(devices);

    if (!sortedDevices.length) {
      return (
        <NonIdealState title="No devices" visual="mobile-phone" />
      );
    }

    return (
      <table className="pt-table pt-interactive" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th onClick={() => this.onSortChange('deviceName')}>
              Device Name{' '}
              {this.state.sortColumn === 'deviceName' && (
                <span className={`pt-icon pt-icon-sort-${this.state.sortDirection === 'asc' ? 'asc' : 'desc'}`} />
              )}
            </th>
            <th width={140} onClick={() => this.onSortChange('deviceModel')}>
              Model{' '}
              {this.state.sortColumn === 'deviceModel' && (
                <span className={`pt-icon pt-icon-sort-${this.state.sortDirection === 'asc' ? 'asc' : 'desc'}`} />
              )}
            </th>
            <th width={140} onClick={() => this.onSortChange('user')}>
              Owner{' '}
              {this.state.sortColumn === 'user' && (
                <span className={`pt-icon pt-icon-sort-${this.state.sortDirection === 'asc' ? 'asc' : 'desc'}`} />
              )}
            </th>
            <th width={160} onClick={() => this.onSortChange('registerDate')}>
              Register Date{' '}
              {this.state.sortColumn === 'registerDate' && (
                <span className={`pt-icon pt-icon-sort-${this.state.sortDirection === 'asc' ? 'asc' : 'desc'}`} />
              )}
            </th>
            <th width={160} onClick={() => this.onSortChange('lastOnlineDate')}>
              Last Online{' '}
              {this.state.sortColumn === 'lastOnlineDate' && (
                <span className={`pt-icon pt-icon-sort-${this.state.sortDirection === 'asc' ? 'asc' : 'desc'}`} />
              )}
            </th>
            <th width={140} onClick={() => this.onSortChange('version')}>
              Client Version{' '}
              {this.state.sortColumn === 'version' && (
                <span className={`pt-icon pt-icon-sort-${this.state.sortDirection === 'asc' ? 'asc' : 'desc'}`} />
              )}
            </th>
            <th width={60}></th>
          </tr>
        </thead>
        <tbody>
          {sortedDevices.map((device) => (
            <tr
              key={device.deviceID}
              onMouseOver={() => {
                this.setState({ activeId: device.deviceID });
              }}
            >
              <td>{device.deviceName}</td>
              <td>{device.deviceModel}</td>
              <td>{device.user}</td>
              <td>{moment(device.registerDate).fromNow()}</td>
              <td>{moment(device.lastOnlineDate).fromNow()}</td>
              <td>{device.version}</td>
              <td>
                {this.state.activeId === device.deviceID && (
                  <div style={{ height: 16 }}>
                    <Button
                      iconName="trash"
                      className="pt-minimal btn-small"
                      intent={Intent.DANGER}
                      onClick={() => this.setState({ remove: device })}
                    />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  render() {
    if (this.state.loading) {
      return <Spinner className="fullscreen-loading"/>
    }
    return (
      <div>
        <Alert
          isOpen={this.state.remove}
          intent={Intent.DANGER}
          iconName='trash'
          confirmButtonText='Remove'
          cancelButtonText='Cancel'
          onCancel={() => {
            this.setState({remove: null})
          }}
          onConfirm={() => {
            const {deviceID} = this.state.remove
            destroy(`admin/devices/${deviceID}`).then(() => {
              this.setState({remove: null, devices: this.state.devices.filter(device => device.deviceID !== deviceID)})
              this.toaster.show({message: 'Delete completed', intent: Intent.SUCCESS})
            }).catch(e => {
              this.setState({remove: null})
              this.toaster.show({message: `Delete failed: ${e.message}`, intent: Intent.DANGER})
            })
          }}
        >
          <span>Are you sure you want to unregistered {(this.state.remove && this.state.remove.deviceName) || 'this device'}? This action cannot be undone.</span>
        </Alert>
        <Toaster ref={this.refHandlers.toaster} />
        {
          this.renderTable(this.state.devices)
        }
      </div>
    )
  }
}

export default Device;        