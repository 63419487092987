import React, { PureComponent } from 'react'
import {Link} from 'react-router-dom'
import InstallGuide from './InstallGuide'
import {Button} from '@blueprintjs/core'
import {post, login} from './util'
import logo from './logo.png'
import { Card } from '@blueprintjs/core'

function isWeiXin() {
  let userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.indexOf('micromessenger') !== -1) {
    return true;
  } else {
    return false;
  }
}

class Install extends PureComponent {

  state = {
    companyID: '',
    userID: '',
    passcode: '',

    loading: false,
    success: false
  }

  onChange(key, value) {
    this.setState({[key]: value.target.value})
  }

  onSubmit = e => {
    e.preventDefault()

    this.setState({loading: true})

    post('portal/install-user-login', {companyID: this.state.companyID, userID: this.state.userID, passcode: this.state.passcode}).then(ret => {
      this.setState({loading: false})

      if (ret.error || !ret.success) {
        alert(ret.error)
        return;
      }

      this.setState({success: true});
    }).catch(e => {
      this.setState({loading: false})
      alert(e.message)
    })
  }

  render() {
    if (isWeiXin()) {
      return (<div><div style={{ margin: '20px auto' }}><Card>请点击右上角按钮，选择“在默认浏览器中打开”。</Card></div></div>)
    }

    return !this.state.success ? (
      <div style={{width: 300, margin: '200px auto'}}>
        <form onSubmit={this.onSubmit}>
          <div style={{position: 'relative', textAlign: 'left', marginBottom: 16, fontSize: 20, paddingTop: 14}}>
            <img style={{position: 'absolute', right: 0, top: 0}} src={logo} alt="Logo" width="40" height="40"/>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push('/')}>Surge Enterprise</span>
          </div>
          <div className="pt-form-group">
            <div className="pt-form-content">
              This page is for authorized users only. Please input your credential to continue.
            </div>
          </div>
          <div className="pt-form-group">
            <div className="pt-form-content">
              <input id="companyID" className="pt-input" style={{width: 300}} placeholder="Company ID" type="text" value={this.state.companyID} onChange={this.onChange.bind(this, 'companyID')} />
            </div>
          </div>
          <div className="pt-form-group">
            <div className="pt-form-content">
              <input id="userID" className="pt-input" style={{width: 300}} placeholder="User ID" type="text" value={this.state.userID} onChange={this.onChange.bind(this, 'userID')} />
            </div>
          </div>

          <div className="pt-form-group">
            <div className="pt-form-content">
              <input id="passcode" className="pt-input" style={{width: 300}} type="password" placeholder="Passcode" value={this.state.passcode} onChange={this.onChange.bind(this, 'passcode')} />
            </div>
          </div>
          <Button iconName="log-in" type="submit" loading={this.state.loading} text="Sign In" />
        </form>
      </div>
    ) : (
      <div className='text-view pt-running-text'>
        <ul className='pt-breadcrumbs'>
          <li><Link className='pt-breadcrumb' to='/'>Home</Link></li>
          <li><span className='pt-breadcrumb pt-breadcrumb-current'>Surge Enterprise Install Guide</span></li>
        </ul>

        <InstallGuide />
      </div>
    )
  }
  
}

export default Install
