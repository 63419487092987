import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'

import Home from './Home'
import App from './App'
import Privacy from './Privacy'
import Login from './Login'
import Install from './Install'

import './index.css'
import Promise from 'promise-polyfill'
import 'whatwg-fetch'

import { unregister } from './registerServiceWorker';
unregister();

if (!window.Promise) {
  window.Promise = Promise
}

(function warning () {
  const i = 'STOP!'
  const j = 'This is a browser feature intended for developers. Don\'t copy-paste anything here to keep your Surge account safe from any would-be thieves.'

  if ((window.chrome || window.safari)) {
    const l = 'font-family:helvetica; font-size:20px; ';
    [
      [i, l + 'font-size:50px; font-weight:bold; color:red; -webkit-text-stroke:4px black;'],
      [j, l + ''],
      ['', '']
    ].forEach(r => setTimeout(console.log.bind(console, '\n%c' + r[0], r[1])))
  }
})()

ReactDOM.render((
  <BrowserRouter>
    <div>
      <Route exact path='/' component={Home} />
      <Route path='/login' component={Login} />
      <Route path='/dashboard' component={App} />
      <Route path='/privacy' component={Privacy} />
      <Route path='/install' component={Install} />

    </div>
  </BrowserRouter>
), document.getElementById('root'))
