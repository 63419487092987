import React, { Component } from 'react'
import {Dialog, Button, Intent } from '@blueprintjs/core'

class AddUser extends Component {
  state = {
    username: ''
  }

  handleEdit = (e) => {
    this.setState({username: e.target.value})
  }

  handleClose = () => {
    this.props.onClose()
    setTimeout(() => {
      this.setState({username: ''})
    }, 1000)
  }

  render() {
    return (
      <Dialog
        isOpen={this.props.show}
        onClose={this.handleClose}
        iconName="new-person"
        title="Create User"
      >
        <div className="pt-dialog-body">
          <div className="pt-form-content">
            <div className="pt-form-group">
              <input id="username" className="pt-input block-element" placeholder="Username" type="text" value={this.state.username} onChange={this.handleEdit} />
              <div className="pt-form-helper-text">Passcode will be generated automatically</div>
            </div>
          </div>
        </div>
        <div className="pt-dialog-footer">
          <div className="pt-dialog-footer-actions">
            <Button onClick={this.handleClose} text="Cancel" />
            <Button
              intent={Intent.PRIMARY}
              onClick={() => {
                this.props.onSave(this.state.username)
                setTimeout(() => {
                  this.setState({username: ''})
                }, 1000)
              }}
              type="submit"
              text="Save"
            />
          </div>
        </div>
      </Dialog>
    )
  }
}

export default AddUser
