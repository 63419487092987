const endpoint = process.env.REACT_APP_API_ENDPOINT

let token = sessionStorage.getItem('xtoken')

export function getToken () {
  return token
}

export function login (_token) {
  token = _token
  sessionStorage.setItem('xtoken', token)
}

export function logout () {
  token = null
  sessionStorage.removeItem('xtoken')
}

function isStatusEmpty (status) {
  return status === 204 || status === 205 || status === 304
}

function getHeaders () {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  if (token) {
    headers.append('X-Token', token)
  }
  return headers
}

function request (method) {
  return (path, body) => (
    fetch(`${endpoint}/${path}`, {
      method,
      body: body && JSON.stringify(body),
      headers: getHeaders()
    }).then(response => {
      const {status} = response
      if (status >= 200 && status < 300) {
        return isStatusEmpty(status) ? null : response.json()
      }

      const offline =
        // Network error
        status === 0 ||
        // Bad gateway (server behind proxies down)
        status === 502 ||
        // Server (as well as proxy) down
        status === 503 ||
        // Server timeout
        status === 504

      if (offline) {
        const statusMsg = response.statusText ? `${response.statusText} [${status}]` : status
        return Promise.reject(new Error(statusMsg))
      }

      if (status === 401) {
        logout()
        window.location.reload()
      }

      return response.json()
        .catch(() => null)
        .then(body => Promise.reject(new Error(body && body.error)))
    })
    )
}

export const get = request('get')
export const post = request('post')
export const put = request('put')
export const destroy = request('delete')
