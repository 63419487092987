import React, { Component } from 'react'
import {Dialog, Button, Intent, Tab2, Tabs2} from '@blueprintjs/core'
import CSV from 'comma-separated-values'
import {post} from './util'

class ImportCSV extends Component {
  state = {
    loading: false,
    csv: ''
  }

  placeholder = ['name,passcode,quota', 'bob,e82hf1s,2', 'jeff,x92pzm9,5', 'david,,10', 'lucy,a91pzjr'].join('\r\n')

  parseCSV() {
    const csv = new CSV(this.state.csv, {cast: false, header: true})
    let parsed
    try {
      parsed = csv.parse()
    } catch (e) {
      return e
    }

    return parsed.map(o => {
      if (o.quota) {
        o.quota = Number(o.quota)
      } else {
        delete o.quota
      }
      if (!o.passcode) {
        delete o.passcode
      }
      return o
    })
  }

  handleEdit = (e) => {
    this.setState({csv: e.target.value})
  }

  handleSave = () => {
    const parsed = this.parseCSV()
    if (!parsed.length) {
      alert('No valid data')
      return
    }
    this.setState({loading: true})
    post('admin/users', {users: parsed}).then(() => {
      this.setState({loading: false})
      this.props.onSave()
    }).catch(e => {
      alert('Failed to add: ' + e.message)
      this.setState({loading: false})
    })
  }

  handleClose = () => {
    this.props.onClose()
    setTimeout(() => {
      this.setState({username: ''})
    }, 1000)
  }

  renderTable() {
    const parsed = this.parseCSV()
    if (parsed instanceof Error) {
      return <p>{parsed.message}</p>
    }

    if (!parsed.length) {
      return <p>No valid data</p>
    }

    return (<table className="pt-table pt-striped" style={{width: '100%'}}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Passcode</th>
          <th>Quota</th>
        </tr>
      </thead>
      <tbody>
        {
          parsed.map(o => (
            <tr key={o.name}>
              <td>{o.name}</td>
              <td>{o.passcode}</td>
              <td className={o.quota === null || typeof o.quota === 'undefined' ? 'default-quota' : ''}>{o.quota || this.props.defaultQuota}</td>
            </tr>
          ))
        }
      </tbody>
    </table>)
  }

  render() {
    return (
      <Dialog
        isOpen={this.props.show}
        onClose={this.handleClose}
        iconName="new-person"
        title="Create User"
      >
        <div className="pt-dialog-body">
          <div className="pt-form-content">
            <div className="pt-form-group">
              <textarea style={{height: 200}} className="pt-input block-element" type="text" value={this.state.csv} onChange={this.handleEdit} />
            </div>
          </div>
          <div className="pt-card">
            <Tabs2 id="Tabs2Example" onChange={this.handleTabChange}>
              <Tab2 id="preview" title="Preview" panel={this.renderTable()} />
              <Tab2 id="help" title="Help" panel={
                <div className="pt-form-helper-text">
                  Every CSV record has three columns: <code>name</code>, <code>passcode</code> and <code>quota</code>. <code>name</code> is required
                  and the other two are optional. The header of the CSV is also required.
                  <p style={{marginTop: 10}}><strong>Sample:</strong></p>
                  <pre><code>{this.placeholder}</code></pre>
                </div>
              } />
            </Tabs2>
          </div>
        </div>
        <div className="pt-dialog-footer">
          <div className="pt-dialog-footer-actions">
            <Button onClick={this.handleClose} text="Cancel" />
            <Button
              loading={this.state.loading}
              intent={Intent.PRIMARY}
              onClick={this.handleSave}
              type="submit"
              text="Add"
            />
          </div>
        </div>
      </Dialog>
    )
  }
}

export default ImportCSV

