import React, { PureComponent } from 'react'
import { get, put } from './util'
import { Spinner, NumericInput, Checkbox, Button, Intent, RadioGroup, Radio } from '@blueprintjs/core'

class Config extends PureComponent {
  state = {
  }

  handleEdit = (key, e) => {
    if (key === 'unifiedProfile') {
      this.setState({ [key]: e.target.checked })
    } else if (key === 'externalProfile') {
      this.setState({ [key]: e.target.value === 'url' })
    } else if (key === 'profileUpdateInterval') {
      this.setState({ [key]: e })
    } else {
      this.setState({ [key]: e.target.value })
    }
  }

  onSave = () => {
    this.setState({ saving: true })
    put('admin/info', {
      unifiedProfile: this.state.unifiedProfile,
      profile: this.state.profile,
      externalProfile: this.state.externalProfile,
      profileURL: this.state.profileURL,
      profileUpdateInterval: this.state.profileUpdateInterval
    }).then(() => {
      this.setState({ saving: false })
      alert('Saved Successfully')
    }).catch(e => {
      alert(e.message ? `Failed to save: ${e.message}` : 'Failed to save')
      this.setState({ saving: false })
    })
  }

  componentDidMount() {
    this.setState({ loading: true })
    get('admin/info').then(({ unifiedProfile, profile, externalProfile, profileURL, profileUpdateInterval }) => {
      this.setState({ unifiedProfile, profile, externalProfile, profileURL, profileUpdateInterval, loading: false })
    }).catch(e => {
      console.error(e)
    })
  }

  render() {
    if (this.state.loading) {
      return <Spinner className="fullscreen-loading" />
    }

    return (
      <div>
        <div className="pt-dialog-body">
          <div className="pt-form-content">
            <Checkbox checked={this.state.unifiedProfile} label="Enable Unified Profile" onChange={this.handleEdit.bind(this, 'unifiedProfile')} />
            {
              this.state.unifiedProfile &&
              <div className="pt-card">
                <RadioGroup
                  label="Profile Content:"
                  onChange={this.handleEdit.bind(this, 'externalProfile')}
                  selectedValue={this.state.externalProfile ? 'url' : 'text'}
                >
                  <Radio className="pt-inline" label="Text" value="text" />
                  <Radio className="pt-inline" label="URL" value="url" />
                </RadioGroup>
                {
                  this.state.externalProfile && <div>
                    <div className="pt-input-group">
                      <span className="pt-icon pt-icon-link"></span>
                      <input type="text" className="pt-input" placeholder="URL of the profile" value={this.state.profileURL || ''} onChange={this.handleEdit.bind(this, 'profileURL')} />
                    </div>
                    <div
                      style={{ marginTop: 5, fontSize: 12, color: '#5c7080' }}
                    >
                      Dynamic values <code>{'{'}username{'}'}</code> and <code>{'{'}passcode{'}'}</code> can be used in the URL.
                    </div>
                  </div>
                }
                {
                  this.state.externalProfile ||
                  <textarea autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" className="pt-input block-element" style={{ height: 500 }} dir="auto" value={this.state.profile || ''} onChange={this.handleEdit.bind(this, 'profile')} />
                }
              </div>
            }
            {
              this.state.unifiedProfile &&
              <div className="pt-card" style={{ marginTop: 20 }}>
                <label className="pt-label">Update Interval (Seconds):</label>
                <NumericInput min={5} max={99999999999} onValueChange={this.handleEdit.bind(this, 'profileUpdateInterval')} value={this.state.profileUpdateInterval || '86400'} />
              </div>
            }
          </div>
        </div>
        <div className="pt-dialog-footer">
          <div className="pt-dialog-footer-actions">
            <Button
              loading={this.state.saving}
              intent={Intent.PRIMARY}
              onClick={() => {
                this.onSave()
              }}
              type="submit"
              text="Save"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Config
